import axios from 'axios'
import Store from '../store'
import { errorHandler, successHandler } from './helpers/handler'
import { BASEURL } from './const'
import adminHttp from './admin/http'

const URL = `/traderposts`

export default {
  traderPost() {
    return {
      createPost: async (data) => {
        const endpoint = `${URL}`
        try {
          const res = await adminHttp.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getPosts: async (params = {}) => {
        const endpoint = `${URL}`
        try {
          const res = await adminHttp.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getASinglePost: async ({ id, params = {} }) => {
        const endpoint = `${URL}/${id}`
        try {
          const res = await adminHttp.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCurrentPost: async ({ params = {} }) => {
        const endpoint = `${BASEURL}/traderposts/user`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updatePost: async ({ id, data }) => {
        const endpoint = `${URL}/${id}`
        try {
          const res = await adminHttp.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deletePost: async ({ id }) => {
        const endpoint = `${URL}/${id}`
        try {
          const res = await adminHttp.delete(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      makePostCurrent: async ({ id }) => {
        const endpoint = `${URL}/${id}/current`
        try {
          const res = await adminHttp.put(
            endpoint,
            {},
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
